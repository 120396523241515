import React from 'react'
import {Link} from 'gatsby'

import Layout from '../../components/layout'
import ServicesMenu from './_menu.js'

const OffsetPage = () => (
  <Layout bgClass="services">
    <ServicesMenu pageName="offset" />

    <div className="textPageDetails">
      <p>
        Nakatomi offers high quality Offset Lithography printing.
        Perfect for high quantity, short turn-around jobs. (But we can also do small jobs as well!)
        Cost effective and fast, this printing can be done on the same paper stock as our screenprinting. Other stock also available - glossy, satin, and more.
      </p><p>
        We have produced offset jobs for clients such as Metallica and Lumineers and The Muppets!
        Pricing varies depending on size, quantity, and time - <Link to="/contact">contact us</Link> directly for a fast quote!
      </p><p>
        Offset print files should be flattened .TIFF files, at least 300dpi, and RGB.
      </p>
    </div>
  </Layout>
)

export default OffsetPage
